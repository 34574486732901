import { ScanLandingImageBannerComponentModel } from './components/ScanLandingImageBannerComponent';
import { ScanLandingImageComponentModel } from './components/ScanLandingImageComponent';
import { ScanLandingMainComponentModel } from './components/ScanLandingMainComponent';
import { ScanLandingReviewComponentModel } from './components/ScanLandingReviewComponent';
import { ScanLandingVideoBannerComponentModel } from './components/ScanLandingVideoBannerComponent';
import { ScanLandingVideoComponentModel } from './components/ScanLandingVideoComponent';
import {
  ScanLandingComponentTypesEnum,
  ScanLandingMainComponentTypesEnum,
} from './enums';

export const INPUT_MAX_LENGTH = 255;

export const emptyMainComponentModel: ScanLandingMainComponentModel = {
  type: ScanLandingMainComponentTypesEnum.IMAGE,
  mobile: { url: '' },
};

export const emptyImageComponentModel: ScanLandingImageComponentModel = {
  title: '',
  description: '',
  mobile: { url: '' },
};

export const emptyImageBannerComponentModel: ScanLandingImageBannerComponentModel =
  {
    title: '',
    description: '',
    references: '',
    mobile: { url: '' },
  };

export const emptyVideoComponentModel: ScanLandingVideoComponentModel = {
  title: '',
  description: '',
  mobile: { url: '' },
};

export const emptyVideoBannerComponentModel: ScanLandingVideoBannerComponentModel =
  {
    title: '',
    description: '',
    references: '',
    autoplay: true,
    controls: true,
    mobile: { url: '', videoByRoles: [] },
  };

export const emptyReviewComponentModel: ScanLandingReviewComponentModel = {
  review: '',
  reviewerName: '',
  reviewerTitle: '',
};

interface TemplateOption {
  components: Array<{ type: ScanLandingComponentTypesEnum; name: string }>;
}
export const TEMPLATE_OPTIONS: TemplateOption[] = [
  {
    components: [{ type: ScanLandingComponentTypesEnum.MAIN, name: 'MAIN' }],
  },
  {
    components: [
      {
        type: ScanLandingComponentTypesEnum.VIDEO_BANNER,
        name: 'VIDEO BANNER',
      },
      { type: ScanLandingComponentTypesEnum.MAIN, name: 'MAIN' },
    ],
  },
  {
    components: [
      {
        type: ScanLandingComponentTypesEnum.IMAGE_BANNER,
        name: 'IMAGE BANNER',
      },
      { type: ScanLandingComponentTypesEnum.MAIN, name: 'MAIN' },
    ],
  },
  {
    components: [
      {
        type: ScanLandingComponentTypesEnum.VIDEO_BANNER,
        name: 'VIDEO BANNER',
      },
      { type: ScanLandingComponentTypesEnum.MAIN, name: 'MAIN' },
      { type: ScanLandingComponentTypesEnum.HOW_TO_USE, name: 'HOW TO USE' },
      { type: ScanLandingComponentTypesEnum.REVIEW, name: 'REVIEW' },
    ],
  },
  {
    components: [
      {
        type: ScanLandingComponentTypesEnum.IMAGE_BANNER,
        name: 'IMAGE BANNER',
      },
      { type: ScanLandingComponentTypesEnum.MAIN, name: 'MAIN' },
      { type: ScanLandingComponentTypesEnum.HOW_TO_USE, name: 'HOW TO USE' },
      { type: ScanLandingComponentTypesEnum.REVIEW, name: 'REVIEW' },
    ],
  },
];

export const ScanLandingRoleEnum = {
  FRIEND: 'Friend',
  LOVER: 'Lover',
  DERMO: 'Dermo',
};

import React from 'react';
import { connect } from 'react-redux';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';
import { ReducersState } from '../reducers';

const WebeatDermoUsersDashboard = (props: any) => {
    const { formatMessage } = props.intl;
    const { LARGE } = config.COMPONENT.COLUMN;

    const componentId = 'WebeatDermoUsersDashboard';

    const components = [
        // Search
        {
            params: {
                type: 'search',
                componentId: 'webeatDermoUsersSearch',
            },
            groups: [
                {
                    index: 0,
                    title: '',
                    mustRender: true,
                    subgroups: [
                        {
                            index: 0,
                            title: '',
                            mustRender: true,
                            yPosition: 0,
                            xPosition: 0,
                            width: 24,
                            fields: [
                                {
                                    key: 'username',
                                    yPosition: 0,
                                    xPosition: 0,
                                    mustRender: true,
                                    width: 12,
                                },
                            ],
                        },
                    ],
                },
            ],
            fields: [
                {
                    title: formatMessage({ id: 'dermoUsers.email' }),
                    key: 'username',
                    type: 'text',
                    operand: 'like',
                    min: 0,
                    validationType: 'text',
                    length: 100,
                    mandatory: false,
                    disabled: false,
                    globalSearch: true,
                },
            ],
            settings: {
                fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
                fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
            },
        },
        // Table
        {
            params: {
                type: 'table',
                componentId: 'webeatDermoUsersList',
                rowKey: 'id',
                panelHeader: 'Dermo Users list',
                navigationParams: props.query.params.q || '',
                isMainTable: true,
                tableType: 0,
                prettierKey: 'id',
            },
            fields: [
                {
                    title: formatMessage({ id: 'dermoUsers.customerId' }),
                    key: 'id',
                    position: 0,
                    mustRender: true,
                    align: 'left',
                    sorter: true,
                    visible: true,
                    width: LARGE,

                },
                {
                    title: formatMessage({ id: 'dermoUsers.email' }),
                    key: 'username',
                    position: 1,
                    mustRender: true,
                    align: 'left',
                    sorter: true,
                    visible: true,
                    width: LARGE,
                },
                {
                    title: formatMessage({ id: 'dermoUsers.roleStatus' }),
                    key: 'roleState',
                    position: 2,
                    mustRender: true,
                    align: 'left',
                    sorter: true,
                    visible: true,
                    width: LARGE,
                },
            ],
            settings: {
                size: config.COMPONENT.TABLE.SIZE,
                bordered: config.COMPONENT.TABLE.BORDERED,
                editable: true,
                multiple: false,
                // TODO: enable this options when the edit part is available
                // canCreate: true,
                // canDelete: true,
                scroll: { x: true },
            },
            pagination: {
                defaultPageSize: 50,
                pageSizeOptions: ['5', '10', '20', '50'],
                showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
                showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
                showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
                size: config.COMPONENT.TABLE.SIZE,
            },
        },
    ];

    return (
        <DashboardInitializer
            dashboardId={componentId}
            components={components}
            {...props}
        />
    );
}

const mapStateToProps = (state: ReducersState) => {
    return {
        currentPlatform: state.app.currentPlatform,
    };
};

export default connect(mapStateToProps)(WebeatDermoUsersDashboard)
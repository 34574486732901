import config from '../config';
import React from 'react';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const ConsumableVariantDashboard = (props: any) => {
  const { SMALL, LARGE } = config.COMPONENT.COLUMN;
  const { formatMessage } = props.intl;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'consumableVariantSearch',
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'variantId',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
                {
                  key: 'consumableId',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 18,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'variant.id' }),
          key: 'variantId',
          type: 'number',
          operand: 'equal',
          globalSearch: false,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'variant.consumable.id' }),
          key: 'consumableId',
          type: 'selector',
          selectorPath: 'consumable/get-consumable-id-list',
          comboId: 'consumableId',
          operand: 'equal',
          globalSearch: true,
          mandatory: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canCreate: true,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'consumableVariantList',
        rowKey: 'id',
        isMainTable: true,
        tabIndex: -1,
        prettierKey: 'title',
        disableFindPageByIdRequests: true,
      },
      fields: [
        {
          title: formatMessage({ id: 'variant.id' }),
          key: 'variantId',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'variant.consumable.id' }),
          key: 'consumableId',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: LARGE,
          render: 'selector',
          selectorPath: 'consumable/get-consumable-id-list',
        },
        {
          title: formatMessage({ id: 'variant.multiclient' }),
          key: 'multiClient',
          position: 2,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
          render: 'comboCustom',
          comboId: 'optional',
        },
        {
          title: formatMessage({ id: 'variant.multiClientScanLimit' }),
          key: 'multiClientScanLimit',
          position: 3,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'variant.customerScanLimit' }),
          key: 'customerScanLimit',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: false,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50', '100', '200'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'consumableVariantsPageEdit',
        panelHeader: 'Edit Consumable Variant',
        primaryKey: 'id',
        tabIndex: 0,
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 2,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 7,
                },
                {
                  key: 'consumableId',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 7,
                },
                {
                  key: 'multiClient',
                  yPosition: 0,
                  xPosition: 2,
                  mustRender: true,
                  width: 7,
                },
                {
                  key: 'multiClientScanLimit',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 7,
                },
                {
                  key: 'customerScanLimit',
                  yPosition: 1,
                  xPosition: 1,
                  mustRender: true,
                  width: 7,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'variant.id' }),
          key: 'id',
          type: 'number',
          unique: true,
          min: 0,
          mandatory: true,
          disabled: false,
          disabledOnEdit: true,
        },
        {
          title: formatMessage({ id: 'variant.consumable.id' }),
          key: 'consumableId',
          type: 'selector',
          selectorPath: 'consumable/get-consumable-id-list',
          multiSelect: false,
          min: 0,
          mandatory: true,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'variant.multiclient' }),
          key: 'multiClient',
          type: 'comboCustom',
          comboId: 'optional',
          min: 0,
          mandatory: true,
          disabled: false,
          initialValue: false,
        },
        {
          title: formatMessage({ id: 'variant.customerScanLimit' }),
          key: 'customerScanLimit',
          type: 'number',
          unique: true,
          min: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'variant.multiClientScanLimit' }),
          key: 'multiClientScanLimit',
          type: 'number',
          unique: true,
          min: 0,
          mandatory: true,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: false,
      },
    },
  ];

  const componentId = 'ConsumableVariantDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default ConsumableVariantDashboard;

import {
  Card,
  Collapse,
  Form,
  Button,
  message,
  Radio,
  Col,
  Icon,
  Typography,
  Input,
  Select,
} from 'antd';
import React, { useState } from 'react';
import { ICompleteField } from '../forms/formInterfaces';
import fieldRender from '../forms/fieldRender';
import UploadComponent from '../challenge/Components/UploadComponent';
import {
  typesOfSelectEnum,
  UPLOAD_ACTION_IMAGE,
  UPLOAD_ACTION_VIDEO,
} from '../shared';
import { ValidationRule } from 'antd/lib/form';

export interface ItemRulesParams {
  index: number;
  format1SimpleBannerLength?: number;
  format2SimpleBannerLength?: number;
  format1DoubleBannerLength?: number;
  format2DoubleBannerLength?: number;
  isRequired?: boolean;
  itemKey?: string;
}

const { Panel } = Collapse;
const { Text } = Typography;
const { Item } = Form;

// Constants
const DUMMY_IMAGE_UUID = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384f';

const VerticalSpacerXS = () => {
  return (
    <div
      style={{
        paddingBottom: '16px',
      }}></div>
  );
};
const VerticalSpacerXXS = () => {
  return (
    <div
      style={{
        paddingBottom: '8px',
      }}></div>
  );
};
const FlexStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
};
const RequiredStyles: React.CSSProperties = {
  marginRight: '4px',
  color: '#F54844',
  fontSize: '14px',
  lineHeight: 1,
  fontFamily: 'sans-serif',
};
const TextStyles: React.CSSProperties = {
  fontWeight: 500,
  fontSize: '16px',
  color: '#000000D',
};

export const WebeatCollapsedListInput = (
  field: ICompleteField,
  parentProps: any,
) => {
  const { handleChangeField, props } = parentProps;
  const { form, values, intl, accessToken, params } = props;
  const { getFieldDecorator } = form;

  const [, forceUpdate] = useState<number>(0);

  const isBannerFeature = field.key === 'gridBanners';

  if (!field.objectArrayFields)
    return <p>This component need objectArrayFields</p>;

  const mustChoseType = field.objectArrayFields.length > 1;

  const isDoubleBanner = (index: number) => {
    return [1, 4, 7].includes(index + 1);
  };

  // Form elements keys
  const getItemKey = (fieldName: string, idx: number) => {
    return `gridBanners[${idx}].${fieldName}`;
  };
  const getFormKey = (fieldName: string, index: number) => {
    return `webeat-collapsed-list__form-item__${fieldName}-${index}`;
  };

  // TODO: Add in future features...
  // Commented in case it is needed in the future
  // const moveItemUp = (index: number) => {
  //   if (index === 0) {
  //     return;
  //   }

  //   const newValues = [...values[field.key]];
  //   const temp = newValues[index];
  //   newValues[index] = newValues[index - 1];
  //   newValues[index - 1] = temp;

  //   handleChangeField({
  //     id: [field.key],
  //     value: newValues,
  //   });
  // };

  // const moveItemDown = (index: number) => {
  //   if (index === values[field.key].length - 1) {
  //     return;
  //   }

  //   const newValues = [...values[field.key]];
  //   const temp = newValues[index];
  //   newValues[index] = newValues[index + 1];
  //   newValues[index + 1] = temp;

  //   handleChangeField({
  //     id: [field.key],
  //     value: newValues,
  //   });
  // };

  const addItem = () => {
    if (
      !values[field.key] ||
      (field.arrayMaxSize && values[field.key].length < field.arrayMaxSize) ||
      !field.arrayMaxSize
    ) {
      const previousBanners = values[field.key] || [];
      const currentLength = previousBanners.length;
      const isSingleBanner = !isDoubleBanner(currentLength);

      handleChangeField({
        id: [field.key],
        value: [
          ...previousBanners,
          {
            type: 'image',
            fromUrl: true,
            isMobileFileAnUrl: true,
            bannerBackgroundColor: isSingleBanner ? 'bg.grey' : null,
          },
        ],
      });
    } else {
      message.error('Ya has alcanzado el límite de ' + field.title);
    }
  };

  const removeItem = (index: number) => {
    let tempBanners = [...values[field.key]];
    tempBanners.splice(index, 1);
    handleChangeField({
      id: [field.key],
      value: tempBanners,
    });
  };

  const handleFieldChange = (innerField: string, value: any, index: number) => {
    const newValues = values[field.key];

    newValues[index][innerField] = value;

    handleChangeField({
      id: [field.key],
      value: newValues,
    });
  };

  const onItemTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const finedItem = field.objectArrayFields?.find(
      (objectField) => objectField.value === e.target.value,
    );
    if (!finedItem)
      return message.error('There was a problem adding your type');

    handleFieldChange('type', finedItem.value, index);
    finedItem.fields.forEach((innerField) => {
      handleFieldChange(innerField.key, innerField.initialValue, index);
    });
  };

  // On change for gridBanners
  const handleInnerChangePageItemProp = (
    value: any,
    key: string,
    idx: number,
  ) => {
    const newValues = {
      ...values.gridBanners[idx],
      [key]: value,
    };

    handleChangeField({
      id: 'gridBanners',
      type: 'Array',
      value: values.gridBanners?.map((item: any, index: number) =>
        index === idx ? newValues : item,
      ),
    });
  };

  const itemRules = (params: ItemRulesParams) => {
    const {
      index,
      format1DoubleBannerLength,
      format1SimpleBannerLength,
      format2DoubleBannerLength,
      format2SimpleBannerLength,
      isRequired,
    } = params;

    const doubleBanner = isDoubleBanner(index);

    const formatTypeValue = values?.gridBanners[index]?.formatType;

    const maxLengthSimpleBanner =
      formatTypeValue === 'FORMAT_1'
        ? format1SimpleBannerLength
        : format2SimpleBannerLength;
    const maxLengthDoubleBanner =
      formatTypeValue === 'FORMAT_1'
        ? format1DoubleBannerLength
        : format2DoubleBannerLength;

    const resultantLength = doubleBanner
      ? maxLengthDoubleBanner
      : maxLengthSimpleBanner;

    const rules: ValidationRule[] | undefined = [
      {
        required: isRequired,
        message: 'This field is required.',
      },
    ];

    if (resultantLength) {
      rules.push({
        max: resultantLength,
        message: `Can only contain ${resultantLength} characters.`,
      });
    }

    return rules || [];
  };

  return (
    <Card
      title={field.title}
      bodyStyle={{ padding: '0px' }}
      style={{ marginBottom: '10px' }}
      extra={<Button onClick={addItem}>Add {field.title}</Button>}>
      <Collapse>
        {values[field.key] &&
          values[field.key].map((item: any, index: number) => (
            <Panel
              extra={
                isBannerFeature ? (
                  [1, 4, 7].includes(index + 1) ? (
                    <Text code type="danger">
                      Banner doble
                    </Text>
                  ) : (
                    <Text code type="secondary">
                      Banner simple
                    </Text>
                  )
                ) : undefined
              }
              header={`${field.title} ${index + 1}`}
              key={index}>
              <Form layout="vertical">
                {mustChoseType ? (
                  <Item label={`Type of ${field.title}`}>
                    {getFieldDecorator(`${field.key}[${index}].type`, {
                      initialValue: undefined,
                    })(
                      <Radio.Group
                        onChange={(e: any) => onItemTypeChange(e, index)}>
                        {field.objectArrayFields?.map((objectField) => (
                          <Radio.Button value={objectField.value}>
                            {objectField.title}
                          </Radio.Button>
                        ))}
                      </Radio.Group>,
                    )}
                  </Item>
                ) : null}
                {field.objectArrayFields
                  ?.find(
                    (objectField) =>
                      objectField.value === item.type || !mustChoseType,
                  )
                  ?.fields.map((innerField) => {
                    if (innerField.type === 'type') {
                      return values?.gridBanners[index]?.formatType ===
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('type', index)}>
                            <>
                              <div style={FlexStyles}>
                                <Text style={RequiredStyles}>*</Text>
                                <Text style={TextStyles}>
                                  Banner media type
                                </Text>
                              </div>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(getItemKey('type', index), {
                                initialValue: item[innerField.key],
                                rules: itemRules({
                                  index,
                                  isRequired: true,
                                }),
                              })(
                                <Select
                                  optionFilterProp="children"
                                  style={{ clear: 'both' }}
                                  size="large"
                                  key={`webeat-collapesed-list-input__select__${index}`}
                                  onChange={(value: string) => {
                                    handleInnerChangePageItemProp(
                                      value,
                                      'type',
                                      index,
                                    );
                                  }}
                                  allowClear={false}>
                                  <Select.Option
                                    key={`gridBanners[${index}].type`}
                                    value="video">
                                    Video
                                  </Select.Option>
                                  <Select.Option
                                    key={`gridBanners[${index}].type`}
                                    value="image">
                                    Image
                                  </Select.Option>
                                </Select>,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    }
                    if (innerField.type === 'imageUrl') {
                      return (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('imageUrl', index)}>
                            {!values[field.key][index].fromUrl ? (
                              values[field.key][index].type === 'image' ? (
                                // Upload Images & gifs
                                <>
                                  <div style={FlexStyles}>
                                    <Text style={RequiredStyles}>*</Text>
                                    {values?.gridBanners[index]?.formatType !==
                                    'MEDIA' ? (
                                      <Text style={TextStyles}>
                                        Banner image
                                      </Text>
                                    ) : (
                                      <Text style={TextStyles}>
                                        Banner image or gif
                                      </Text>
                                    )}
                                  </div>
                                  <VerticalSpacerXXS />
                                  <UploadComponent
                                    format={'image'}
                                    token={accessToken}
                                    uploadActions={[UPLOAD_ACTION_IMAGE]}
                                    materialType={typesOfSelectEnum.IMAGE}
                                    value={item[innerField.key]}
                                    module="banner"
                                    primaryEntityId={
                                      values[params.primaryKey] ||
                                      DUMMY_IMAGE_UUID
                                    }
                                    handleChangeField={(data: any) => {
                                      handleFieldChange(
                                        innerField.key,
                                        data.value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}
                                    setValue={function (
                                      value?: string | null | undefined,
                                    ): void {
                                      handleFieldChange(
                                        innerField.key,
                                        value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}>
                                    <Button
                                      className="uploadButton uploadButton__margin-top"
                                      disabled={innerField.disabled}>
                                      <Icon type="upload" />
                                      {intl.formatMessage({
                                        id: 'upload.buttonTitle',
                                      })}
                                    </Button>
                                  </UploadComponent>
                                </>
                              ) : (
                                // Upload Video
                                <>
                                  <div style={FlexStyles}>
                                    <Text style={RequiredStyles}>*</Text>
                                    <Text style={TextStyles}>Banner video</Text>
                                  </div>
                                  <VerticalSpacerXXS />
                                  <UploadComponent
                                    format={'video'}
                                    token={accessToken}
                                    uploadActions={[UPLOAD_ACTION_VIDEO]}
                                    materialType={typesOfSelectEnum.VIDEO}
                                    value={item[innerField.key]}
                                    module="banner"
                                    primaryEntityId={
                                      values[params.primaryKey] ||
                                      DUMMY_IMAGE_UUID
                                    }
                                    handleChangeField={(data: any) => {
                                      handleFieldChange(
                                        innerField.key,
                                        data.value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}
                                    setValue={function (
                                      value?: string | null | undefined,
                                    ): void {
                                      handleFieldChange(
                                        innerField.key,
                                        value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}>
                                    <Button
                                      className="uploadButton uploadButton__margin-top"
                                      disabled={innerField.disabled}>
                                      <Icon type="upload" />
                                      {intl.formatMessage({
                                        id: 'upload.buttonTitle',
                                      })}
                                    </Button>
                                  </UploadComponent>
                                </>
                              )
                            ) : (
                              <>
                                <div style={FlexStyles}>
                                  <Text style={RequiredStyles}>*</Text>
                                  <Text style={TextStyles}>Banner url</Text>
                                </div>
                                <VerticalSpacerXXS />
                                {getFieldDecorator(
                                  getItemKey('imageUrl', index),
                                  {
                                    initialValue: item[innerField.key],
                                    rules: itemRules({
                                      index,
                                      isRequired: true,
                                    }),
                                  },
                                )(
                                  <Input
                                    type="text"
                                    title={field.title}
                                    defaultValue={item[innerField.key]}
                                    disabled={innerField.disabled || false}
                                    onBlur={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleFieldChange(
                                        innerField.key,
                                        e.target.value,
                                        index,
                                      )
                                    }
                                  />,
                                )}
                              </>
                            )}
                          </Form.Item>
                          {values?.gridBanners[index]?.formatType !==
                          'MEDIA' ? (
                            <Text>
                              {[1, 4, 7].includes(index + 1)
                                ? intl.formatMessage({
                                    id: 'userMenu.banner.upload.doubleBanner',
                                  })
                                : intl.formatMessage({
                                    id: 'userMenu.banner.upload.singleBanner',
                                  })}
                            </Text>
                          ) : null}
                          <VerticalSpacerXS />
                        </Col>
                      );

                      // Custom Banner fields due to format issues
                    } else if (innerField.type === 'bannerButtonUrl') {
                      return (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerVuttonUrl', index)}>
                            <>
                              {values?.gridBanners[index]?.formatType ===
                              'MEDIA' ? (
                                <Text style={TextStyles}>
                                  Banner redirect URL
                                </Text>
                              ) : (
                                <Text style={TextStyles}>
                                  Banner Button URL
                                </Text>
                              )}
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerButtonUrl', index),
                                {
                                  initialValue: item[innerField.key],
                                },
                              )(
                                <Input
                                  type="text"
                                  title={field.title}
                                  defaultValue={item[innerField.key]}
                                  disabled={innerField.disabled || false}
                                  onBlur={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleFieldChange(
                                      innerField.key,
                                      e.target.value,
                                      index,
                                    )
                                  }
                                />,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      );
                    } else if (innerField.type === 'bannerTitle') {
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerTitle', index)}>
                            <>
                              <div style={FlexStyles}>
                                <Text style={RequiredStyles}>*</Text>
                                <Text style={TextStyles}>Banner Title</Text>
                              </div>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerTitle', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    format1DoubleBannerLength: 20,
                                    format2DoubleBannerLength: 50,
                                    format1SimpleBannerLength: 20,
                                    format2SimpleBannerLength: 20,
                                    isRequired: true,
                                    itemKey: innerField.key,
                                  }),
                                },
                              )(
                                <Input
                                  type="text"
                                  title={field.title}
                                  defaultValue={item[innerField.key]}
                                  disabled={innerField.disabled || false}
                                  onBlur={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleFieldChange(
                                      innerField.key,
                                      e.target.value,
                                      index,
                                    )
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    handleFieldChange(
                                      innerField.key,
                                      e.target.value,
                                      index,
                                    );
                                  }}
                                />,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.type === 'bannerPretitle') {
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerPretitle', index)}>
                            <>
                              <Text style={TextStyles}>Banner Pretitle</Text>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerPretitle', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    format1DoubleBannerLength: 20,
                                    format2DoubleBannerLength: 20,
                                    format1SimpleBannerLength: 26,
                                    format2SimpleBannerLength: 26,
                                    isRequired: false,
                                  }),
                                },
                              )(
                                <Input
                                  type="text"
                                  title={field.title}
                                  defaultValue={item[innerField.key]}
                                  disabled={innerField.disabled || false}
                                  onBlur={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleFieldChange(
                                      innerField.key,
                                      e.target.value,
                                      index,
                                    )
                                  }
                                />,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.type === 'bannerButtonText') {
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerButtonText', index)}>
                            <>
                              <div style={FlexStyles}>
                                {values?.gridBanners[index]?.bannerButtonUrl
                                  ?.length ? (
                                  <Text style={RequiredStyles}>*</Text>
                                ) : null}
                                <Text style={TextStyles}>
                                  Banner button text
                                </Text>
                              </div>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerButtonText', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    format1DoubleBannerLength: 20,
                                    format2DoubleBannerLength: 20,
                                    format1SimpleBannerLength: 11,
                                    format2SimpleBannerLength: 11,
                                    isRequired:
                                      values?.gridBanners[index]
                                        ?.bannerButtonUrl?.length ?? false,
                                  }),
                                },
                              )(
                                <Input
                                  type="text"
                                  title={field.title}
                                  defaultValue={item[innerField.key]}
                                  disabled={innerField.disabled || false}
                                  onBlur={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleFieldChange(
                                      innerField.key,
                                      e.target.value,
                                      index,
                                    )
                                  }
                                />,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.type === 'color') {
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('color', index)}>
                            <>
                              <div style={FlexStyles}>
                                <Text style={RequiredStyles}>*</Text>
                                <Text style={TextStyles}>
                                  Banner elements color
                                </Text>
                              </div>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(getItemKey('color', index), {
                                initialValue: item[innerField.key],
                                rules: itemRules({
                                  index,
                                  isRequired: true,
                                }),
                              })(
                                <Select
                                  optionFilterProp="children"
                                  style={{ clear: 'both' }}
                                  size="large"
                                  key={`webeat-collapsed-input__select__color__${index}`}
                                  onChange={(value: string) => {
                                    handleInnerChangePageItemProp(
                                      value,
                                      'color',
                                      index,
                                    );
                                  }}
                                  allowClear={false}>
                                  <Select.Option
                                    key={`gridBanners[${index}].color`}
                                    value="primary">
                                    Primary
                                  </Select.Option>
                                  <Select.Option
                                    key={`gridBanners[${index}].color`}
                                    value="primary-reversed">
                                    Primary reversed
                                  </Select.Option>
                                </Select>,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.type === 'bannerPosition') {
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' && isDoubleBanner(index) ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerPosition', index)}>
                            <>
                              <div style={FlexStyles}>
                                <Text style={RequiredStyles}>*</Text>
                                <Text style={TextStyles}>Banner position</Text>
                              </div>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerPosition', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    isRequired: true,
                                  }),
                                },
                              )(
                                <Select
                                  optionFilterProp="children"
                                  style={{ clear: 'both' }}
                                  size="large"
                                  key={
                                    'webeat-collapsed-input__select__banner-position'
                                  }
                                  onChange={(value: string) => {
                                    handleInnerChangePageItemProp(
                                      value,
                                      'bannerPosition',
                                      index,
                                    );
                                  }}
                                  allowClear={false}>
                                  <Select.Option
                                    key={`gridBanners[${index}].bannerPosition`}
                                    value="top-left">
                                    Top-Left
                                  </Select.Option>
                                  <Select.Option
                                    key={`gridBanners[${index}].bannerPosition`}
                                    value="bottom-left">
                                    Bottom-Left
                                  </Select.Option>
                                </Select>,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.type === 'bannerParagraph') {
                      return (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerParagraph', index)}>
                            {values?.gridBanners[index]?.formatType !==
                              'MEDIA' && !isDoubleBanner(index) ? (
                              <>
                                <Text style={TextStyles}>Banner paragraph</Text>
                                <VerticalSpacerXXS />
                                {getFieldDecorator(
                                  getItemKey('bannerParagraph', index),
                                  {
                                    initialValue: item[innerField.key],
                                    rules: itemRules({
                                      index,
                                      format1DoubleBannerLength: 60,
                                      format2DoubleBannerLength: 60,
                                      format1SimpleBannerLength: 60,
                                      format2SimpleBannerLength: 60,
                                      isRequired: false,
                                    }),
                                  },
                                )(
                                  <Input
                                    type="text"
                                    title={field.title}
                                    defaultValue={item[innerField.key]}
                                    disabled={innerField.disabled || false}
                                    onBlur={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleFieldChange(
                                        innerField.key,
                                        e.target.value,
                                        index,
                                      )
                                    }
                                  />,
                                )}
                              </>
                            ) : null}
                          </Form.Item>
                        </Col>
                      );
                    } else if (innerField.type === 'bannerLegalText') {
                      const helperText =
                        '* Introduce entre corchetes "{}" el texto que deseas que sea clicable. Ejemplo de uso: Pulsa {aquí} para leer las condiciones de la compra.';
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerLegalText', index)}>
                            <>
                              <Text style={TextStyles}>Banner Legal Text</Text>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerLegalText', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    format1DoubleBannerLength: 100,
                                    format2DoubleBannerLength: 100,
                                    format1SimpleBannerLength: 40,
                                    format2SimpleBannerLength: 40,
                                  }),
                                },
                              )(
                                <Input
                                  type="text"
                                  title={field.title}
                                  defaultValue={item[innerField.key] ?? ''}
                                  disabled={innerField.disabled || false}
                                  onBlur={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleFieldChange(
                                      innerField.key,
                                      e.target.value,
                                      index,
                                    )
                                  }
                                />,
                              )}
                              <Text>{helperText}</Text>
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.type === 'bannerPopUpTitle') {
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerPopUpTitle', index)}>
                            <>
                              <Text style={TextStyles}>
                                Banner Pop-up title
                              </Text>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerPopUpTitle', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    isRequired: false,
                                  }),
                                },
                              )(
                                <Input
                                  type="text"
                                  title={field.title}
                                  defaultValue={item[innerField.key]}
                                  disabled={innerField.disabled || false}
                                  onBlur={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleFieldChange(
                                      innerField.key,
                                      e.target.value,
                                      index,
                                    )
                                  }
                                />,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.type === 'bannerPopUpBody') {
                      return values?.gridBanners[index]?.formatType !==
                        'MEDIA' ? (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('bannerPopUpBody', index)}>
                            <>
                              <Text style={TextStyles}>Banner Pop-up body</Text>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('bannerPopUpBody', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    isRequired: false,
                                  }),
                                },
                              )(
                                <Input
                                  type="text"
                                  title={field.title}
                                  defaultValue={item[innerField.key]}
                                  disabled={innerField.disabled || false}
                                  onBlur={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleFieldChange(
                                      `bannerPopUpBody`,
                                      e.target.value,
                                      index,
                                    )
                                  }
                                />,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      ) : null;
                    } else if (innerField.key === 'formatType') {
                      const options = [
                        <Select.Option
                          key={`gridBanners[${index}].formatType`}
                          value="FORMAT_1">
                          Format 1
                        </Select.Option>,
                        <Select.Option
                          key={`gridBanners[${index}].formatType`}
                          value="FORMAT_2">
                          Format 2
                        </Select.Option>,
                      ];

                      if (isDoubleBanner(index)) {
                        options.push(
                          <Select.Option
                            key={`gridBanners[${index}].formatType`}
                            value="MEDIA">
                            Media
                          </Select.Option>,
                        );
                      }

                      return (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('formatType', index)}>
                            <>
                              <div style={FlexStyles}>
                                <Text style={RequiredStyles}>*</Text>
                                <Text style={TextStyles}>
                                  Banner Format Type
                                </Text>
                              </div>
                              <VerticalSpacerXXS />
                              {getFieldDecorator(
                                getItemKey('formatType', index),
                                {
                                  initialValue: item[innerField.key],
                                  rules: itemRules({
                                    index,
                                    isRequired: true,
                                  }),
                                },
                              )(
                                <Select
                                  optionFilterProp="children"
                                  style={{ clear: 'both' }}
                                  size="large"
                                  key={
                                    'webeat-collapsed-input__select__format-type'
                                  }
                                  onChange={(value: string) => {
                                    handleInnerChangePageItemProp(
                                      value,
                                      'formatType',
                                      index,
                                    );
                                  }}
                                  allowClear={false}>
                                  {options}
                                </Select>,
                              )}
                            </>
                          </Form.Item>
                        </Col>
                      );
                    } else if (innerField.type === 'mobileImageUrl') {
                      return (
                        <Col lg={innerField.width} md={24} key={innerField.key}>
                          <Form.Item
                            label={innerField.title}
                            key={getFormKey('mobileImageUrl', index)}>
                            {!values[field.key][index].isMobileFileAnUrl ? (
                              values[field.key][index].type === 'image' ? (
                                // Upload Images & gifs
                                <>
                                  <div style={FlexStyles}>
                                    <Text style={RequiredStyles}>*</Text>
                                    {values?.gridBanners[index]?.formatType !==
                                    'MEDIA' ? (
                                      <Text style={TextStyles}>
                                        Banner mobile image
                                      </Text>
                                    ) : (
                                      <Text style={TextStyles}>
                                        Banner mobile image or gif
                                      </Text>
                                    )}
                                  </div>
                                  <VerticalSpacerXXS />
                                  <UploadComponent
                                    format={'image'}
                                    token={accessToken}
                                    uploadActions={[UPLOAD_ACTION_IMAGE]}
                                    materialType={typesOfSelectEnum.IMAGE}
                                    value={item[innerField.key]}
                                    module="banner"
                                    primaryEntityId={
                                      values[params.primaryKey] ||
                                      DUMMY_IMAGE_UUID
                                    }
                                    handleChangeField={(data: any) => {
                                      handleFieldChange(
                                        innerField.key,
                                        data.value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}
                                    setValue={function (
                                      value?: string | null | undefined,
                                    ): void {
                                      handleFieldChange(
                                        innerField.key,
                                        value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}>
                                    <Button
                                      className="uploadButton uploadButton__margin-top"
                                      disabled={innerField.disabled}>
                                      <Icon type="upload" />
                                      {intl.formatMessage({
                                        id: 'upload.buttonTitle',
                                      })}
                                    </Button>
                                  </UploadComponent>
                                </>
                              ) : (
                                // Upload Video
                                <>
                                  <div style={FlexStyles}>
                                    <Text style={RequiredStyles}>*</Text>
                                    <Text style={TextStyles}>
                                      Banner mobile video
                                    </Text>
                                  </div>
                                  <VerticalSpacerXXS />
                                  <UploadComponent
                                    format={'video'}
                                    token={accessToken}
                                    uploadActions={[UPLOAD_ACTION_VIDEO]}
                                    materialType={typesOfSelectEnum.VIDEO}
                                    value={item[innerField.key]}
                                    module="banner"
                                    primaryEntityId={
                                      values[params.primaryKey] ||
                                      DUMMY_IMAGE_UUID
                                    }
                                    handleChangeField={(data: any) => {
                                      handleFieldChange(
                                        innerField.key,
                                        data.value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}
                                    setValue={function (
                                      value?: string | null | undefined,
                                    ): void {
                                      handleFieldChange(
                                        innerField.key,
                                        value,
                                        index,
                                      );
                                      forceUpdate(Math.random());
                                    }}>
                                    <Button
                                      className="uploadButton uploadButton__margin-top"
                                      disabled={innerField.disabled}>
                                      <Icon type="upload" />
                                      {intl.formatMessage({
                                        id: 'upload.buttonTitle',
                                      })}
                                    </Button>
                                  </UploadComponent>
                                </>
                              )
                            ) : (
                              <>
                                <div style={FlexStyles}>
                                  <Text style={RequiredStyles}>*</Text>
                                  <Text style={TextStyles}>Banner url</Text>
                                </div>
                                <VerticalSpacerXXS />
                                {getFieldDecorator(
                                  getItemKey('imageUrl', index),
                                  {
                                    initialValue: item[innerField.key],
                                    rules: itemRules({
                                      index,
                                      isRequired: true,
                                    }),
                                  },
                                )(
                                  <Input
                                    type="text"
                                    title={field.title}
                                    defaultValue={item[innerField.key]}
                                    disabled={innerField.disabled || false}
                                    onBlur={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleFieldChange(
                                        innerField.key,
                                        e.target.value,
                                        index,
                                      )
                                    }
                                  />,
                                )}
                              </>
                            )}
                          </Form.Item>
                          {values?.gridBanners[index]?.formatType !==
                          'MEDIA' ? (
                            <Text>
                              {[1, 4, 7].includes(index + 1)
                                ? intl.formatMessage({
                                    id: 'userMenu.banner.upload.mobileDoubleBanner',
                                  })
                                : intl.formatMessage({
                                    id: 'userMenu.banner.upload.mobileSingleBanner',
                                  })}
                            </Text>
                          ) : null}
                          <VerticalSpacerXS />
                        </Col>
                      );
                    }

                    // Only for render the background color selector if a simple banner is selected
                    if (
                      isDoubleBanner(index) &&
                      innerField.key === 'bannerBackgroundColor'
                    ) {
                      return null;
                    }

                    return fieldRender(
                      {
                        ...innerField,
                        key: `${field.key}[${index}].${innerField.key}`,
                      },
                      {
                        ...parentProps,
                        handleChangeField: (data: any) => {
                          handleFieldChange(innerField.key, data.value, index);
                        },
                      },
                    );
                  }) || null}

                {/*   // TODO: Add in future features... Commented in case it is needed in the future */}
                {/* 
                <Button
                  disabled={index === 0}
                  icon="up"
                  onClick={() => moveItemUp(index)}></Button>
                <Button
                  disabled={index === values[field.key].length - 1}
                  icon="down"
                  onClick={() => moveItemDown(index)}></Button>
                */}

                <Button
                  type="danger"
                  icon="delete"
                  onClick={() => removeItem(index)}></Button>
              </Form>
            </Panel>
          ))}
      </Collapse>
    </Card>
  );
};
